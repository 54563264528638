import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const customerDetailsApi = createApi({
    reducerPath: "customerDetails",
    baseQuery: fetchBaseQuery({
        baseUrl: config().SALESFORCE_URL
    }),
    tagTypes: ['customer-details'],
    endpoints: (builder) => ({
        postSfCustomerDetail: builder.mutation<any, void>({
            query: (customerInfo) => ({
                url: "/customerDetails",
                method: "POST",
                body: customerInfo,
                headers: {
                    Authorization: localStorage.getItem('sf_auth_token'),
                    'Content-Type': 'application/json', // Set content type to JSON
                }
            }),
            invalidatesTags: ['customer-details']
        })
    }),

});

export const customerDocDownloadApi = createApi({
    reducerPath: "customerDocDownload",
    baseQuery: fetchBaseQuery({
        baseUrl: config().SALESFORCE_DOWNLOAD_API_URL
    }),
    tagTypes: ['sf-document-download'],
    endpoints: (builder) => ({
        postDownloadSFDoc: builder.mutation<any, void>({
            query: (docInfo) => ({
                url: "/documentDownload",
                method: "POST",
                body: docInfo,
                headers: {
                    Authorization: localStorage.getItem('sf_auth_token'),
                    'Content-Type': 'application/json', // Set content type to JSON
                }
            }),
            invalidatesTags: ['sf-document-download']
        }),

    }),

});

export const { usePostSfCustomerDetailMutation } = customerDetailsApi;
export const { usePostDownloadSFDocMutation } = customerDocDownloadApi;
