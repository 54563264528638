export const PATH = {
  LOGIN: "/",
  SIGNUP: "/signup",
  LAYOUT: "/layout",
  HOME_CUSTOMER: "/customer/home",
  CALENDAR: "/calendar",
  HOME_ADVCANCE_PAID_CUSTOMER: "/adv-paid-customer/home",
  PROPERTY_DETAILS: "/home/propertyDetails",
  REGISTRATION: "/registration",
  ADD_SUPPORT: "/addSupport",
  REFERRAL: "/home/referral",
  REFERRAL_LIST: "/referralList",
  SUPPORT_LIST: "/supportList",
  PRODUCT_DETAILS: "/productDetails/:productType?/:id?",
  PURCHASE_CART: "/purchase-cart",
  MY_PROFILE: "/myProfile",
  ENRICH_LIST: "/enrichList",
  COMMUNITY_ENRICH_LIST: "/products/:type?/:pageNumber?",
  ADD_PRODUCT: "/product/:type?/:id?",
  ADD_VISITOR: "/addVisitor",
  CASA_POINTS: "/casaPoints",
  YOUR_ORDER: "/product-service/my-orders",
  // EVENT_JOIN: "/eventJoin/:type?/:id?",
  EVENT_JOIN: "/association-events/join-event/:type?/:id?",
  EVENT_CREATION: "/eventCreation",
  EVENT_LIST: "/eventList",
  MY_EVENTS: "/myEvents",
  FORGET_PASSWORD_TYPE: "/password-update/:type?",
  FORGET_PASSWORD: "/password-update",
  EXISTING_PASSWORD_UPDATE: "/password-update/user-exit",
  TENANT_TYPE:"/tenant/:type?/:id?",
  ADD_TENANT: "/tenant",
  SOS_CONTACT: "/sosContact",
  ENQUIRY_LIST: "/community-enrich-enquiry-list",
  MY_ENQUIRY_LIST: "/my-enquires/community-enrich-enquiry-list/",
  PAYMENT_INTEGRATION: "/enrich-checkout-payment-integration",
  SERVICES: "/services",
  SERVICE_LIST: "/serviceList",
  LOADER: "/loader",
  ENRICHSERVICE_DETAILS: "/serviceDetails/",
  AMENITIES_LIST: "/amenities/:type?/:id?",
  // AMENITIES_Booking: "/amenities/:type?/:id?",
  MAINTENANCE_DUE_TYPE: "/maintenance-due/:type?",
  MAINTENANCE_DUE: "/maintenance-due/",
  SUMMARY_PAGE: "/summarypage",
  FORUM_CHAT_TYPE: "/forum-chat/:type?/:method?",
  FORUM_CHAT: "/forum-chat/",
  ADD_POLL: "/Poll/:type?/:edit?",
  DAILY_HELPS: "/dailyHelps",
  MY_HELPERS:"/myHelpers",
  DAILY_HELP_LIST: "/dailyHelpList/:categories",
  NOTIFICATION_BOARD: "/notification-board",
  MY_VISITOR_LIST: "/my-visito-list",
  PRIVACY_POLICY:"/privacy-policy",
  ABOUT_US:"/about-us",
  REQUEST_APPOINTMENT_LIST:"/customer/req-appoinment-list",
  MY_PRODUCT_LIST:"/my-product-List",
  MY_GALLERY:"/my-gallery",
  TENANT_LIST:"/tenant-list",
  FITTED_HOMES:"/fitted-homes",
  ADD_TESTIMONIAL:"/add-testimonial",
  RENT_SELL_ASSURE:"/rent-sell-assure",
  TESTIMONIAL_LIST:"/testimonial-List",
  DEACTIVATE_ACCOUNT:"/deactivate-account",
  OFFER_ZONE_LIST:"/offer-zone-list/shop",
  OFFER_ZONE_LIST_PRODUCT:"/offer-zone-list/shop-product/:shopId?",
  DOCUMENT_VIEW: "/document-view",
};
