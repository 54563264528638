export const config = () => {
    let LOGIN_API: string;
    let emailValidator: any;
    let BASE_URL: string;
    let SALESFORCE_URL: string;
    let SALESFORCE_DOWNLOAD_API_URL: string;
    let SALESFORCE_AUTH_URL: string;

    BASE_URL = process.env.REACT_APP_API_URL;
    SALESFORCE_URL = process.env.REACT_APP_SALESFORCE_API_URL;
    SALESFORCE_DOWNLOAD_API_URL = process.env.REACT_APP_SALESFORCE_DOWNLOAD_API_URL;
    SALESFORCE_AUTH_URL = process.env.REACT_APP_SALESFORCE_AUTH_API_URL;

    return {
        LOGIN_API,
        BASE_URL,
        emailValidator,
        SALESFORCE_URL,
        SALESFORCE_DOWNLOAD_API_URL,
        SALESFORCE_AUTH_URL
    };
};
