import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const changePasswordApi = createApi({
    reducerPath: "changePasswordApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('token')}`)
            return headers
        }
    }),

    tagTypes: ['change-password/'],
    endpoints: (builder) => ({
        changePassword: builder.mutation<any, any>({
            query: (changePasswordInfo) => ({
                url: "/change-password/",
                method: "POST",
                body: changePasswordInfo,
            }),
        }),
    }),
});

export const { useChangePasswordMutation } = changePasswordApi;