import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";
// invalidatesTags: (result, error, { pages }) => {
//     return [{ type: 'forum', id: pages }];
//   },
export const forumApi = createApi({
    reducerPath: "forumApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
            return headers
        }
    }),
    tagTypes: ['forum'],
    endpoints: (builder) => ({
        getListOfForum: builder.query<any, { pages: number, searchName: string }>({
            query: ({ pages, searchName }) => ({
                url: "/forum/",
                method: "GET",
                params: {
                    search: searchName,
                    page: String(pages),
                    currentPage: String(pages)
                },
            }),
            // providesTags: (result:any, error:any, { pages }:any) => [{ type: 'forum', id: pages }],
            // invalidatesTags: (result:any, error:any, { pages }:any) => {
            //     return [{ type: 'forum', id: pages }];
            //   }, // Invalidate page 5

            providesTags: ['forum']
        }),

        addForum: builder.mutation<any, any>({
            query: (ForumData) => ({
                url: "/forum/",
                method: "POST",
                body: ForumData,
            }),
            invalidatesTags: ['forum']
        }),
        deleteForum: builder.mutation<any, any>({
            query: (id) => ({
                url: `/forum/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ['forum']
        }),
        updateForum: builder.mutation<any, { forumData: any, forumId: any }>({
            query: ({ forumData, forumId }) => ({
                url: `/forum/${forumId}/`,
                method: "PATCH",
                body: forumData,
            }),
            invalidatesTags: ['forum']
        }),
        likeForum: builder.mutation<any, { forumId: any }>({
            query: ({ forumId }) => ({
                url: `/forum/forum_post_likes_update/?post_id=${forumId}`,
                method: "PATCH",
            }),
            invalidatesTags: ['forum']
        }),
        dislikeForum: builder.mutation<any, { forumId: any }>({
            query: ({ forumId }) => ({
                url: `/forum/post_dislikes/?post_id=${forumId}`,
                method: "PATCH",
            }),
            invalidatesTags: ['forum']
        }),
        selectPollOption: builder.mutation<any, any>({
            query: (optionsData: any) => ({
                url: `/forum/forum_poll_answers/?poll_id=${optionsData?.id}`,
                method: "PUT",
                body: optionsData?.options
            }),
            invalidatesTags: ['forum']
        }),
        createPoll: builder.mutation<any, object>({
            query: (addPollInfo: any) => ({
                url: "/forum/",
                method: "POST",
                body: addPollInfo,
            }),
            invalidatesTags: ["forum"],
        }),
        reportPost: builder.mutation<any, object>({
            query: (reportInfo: any) => ({
                url: "/forum/forum_post_create_report/",
                method: "PUT",
                params: {
                    post_id: reportInfo.post_id,
                },
                body: reportInfo.commentObj,
            }),
            invalidatesTags: ["forum"],
        }),
        singleForumPost: builder.query<any, {postId:any}>({
            query: ({postId}) => ({
                url: `/forum/${postId}/`,
                method: "GET",
            }),
            providesTags: ["forum"],
        }),
        postComment: builder.mutation<any, {postId:any,postData:any}>({
            query: ({postId,postData}) => ({
                url: `/forum/comments/?post_id=${postId}`,
                method: "PUT",
                body: postData
                ,
            }),
            invalidatesTags: ["forum"],
        }),
        deleteComment: builder.mutation<any, {commentId:any}>({
            query: ({commentId}) => ({
                url: `/forum/delete_comment/?comment_id=${commentId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["forum"],
        }),
      
    }),
});

export const { useGetListOfForumQuery, useAddForumMutation, useDeleteForumMutation, useUpdateForumMutation, useLikeForumMutation,useDislikeForumMutation, useSelectPollOptionMutation, useCreatePollMutation ,useReportPostMutation ,useSingleForumPostQuery,usePostCommentMutation,useDeleteCommentMutation} = forumApi;
