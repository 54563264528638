import { useCustomNavigate } from '../../../lib/navigationHook'
import loading from '../../assets/images/loading.gif';
import styles from './loading.module.scss'

function Index() {
  const navigate = useCustomNavigate()
  return (
    // <div>
    //   <div className={styles.loadingSec}>
    //     <div className={styles.loadingWrapper}>
    //     <img src={loading} className={styles.loadingImage} />        
    //     </div>
    //   </div>
    // </div>

    <div className='custom-content-loader'>
      <span className={styles.loader + " loader"}></span>
    </div>
  )
}

export default Index