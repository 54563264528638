
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";
export const communityEnrich = createApi({
    reducerPath: "communityEnrich",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,

        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['community-enrich-product-list', 'enquire','community-enrich-myenquiries'],
    endpoints: (builder) => ({
        getAllCommunityEnrich: builder.query<any, { applyCategories: string[] }>({
            query: ({ applyCategories }) => ({
                url: "/community-enrich-product-list/",
                method: "GET",
                params: {
                    categories: applyCategories.join(','),
                    show_all: "True"
                },
            }),

            providesTags: ['community-enrich-product-list']
        }),
        getAllCommunityEnrichProducts: builder.query<any, {  pages: number }>({
            query: ({ pages }) => ({
                url: "/community-enrich-product-list/",
                method: "GET",
                params: {
                    page: String(pages),
                },
            }),
        }),
        postEnrichProducts: builder.mutation<any, void>({
            query: (prosuctInfo) => ({
                url: "/community-enrich-product-list/",
                method: "POST",
                body: prosuctInfo
            }),
            invalidatesTags: ['community-enrich-product-list']
        }),
        getCommunityEnrichProductDetail: builder.query<any, void>({
            query: (id) => ({
                url: `/community-enrich-product-list/${id}`,
                method: "GET",

            }),
            providesTags: ['community-enrich-product-list']
        }),
        CommunityEnrichEnquire: builder.mutation<any, void>({
            query: (id) => ({
                url: `/community-enrich-product-list/${id}/`,
                method: "PUT",
                params: {
                    enquired: 'True',
                },
            }),
            invalidatesTags: ['community-enrich-product-list',]
        }),
        CommunityEnrichEnquireList: builder.query<any, void>({
            query: () => ({
                url: `/enquiry-list/`,
                method: "GET",
            }),
        }),
        myEnquireList: builder.query<any, void>({
            query: () => ({               
                url:`/community-enrich-myenquiries/`,
                method: "GET",
            }),
        }),
        getMyProductsAndService: builder.query<any, any>({
            query: (productInfo:any) => ({               
                url:`/community-enrich-product-list/my_items/`,
                method: "GET",
                params:{
                    type:'all',
                    search:"",
                    page:productInfo.page
                }
            }),
            providesTags:['community-enrich-product-list']
        }),
        editProductOrService: builder.mutation<any, any>({
            query: (editInfo:any) => ({
                url: `/community-enrich-product-list/${editInfo?.id}/update_item/`,
                method: "PUT",
                body:editInfo?.formData
            }),
            invalidatesTags: ['community-enrich-product-list',]
        }),
        deleteProductService: builder.mutation<any, any>({
            query: (id:any) => ({//community-enrich-product-list/{id}/
                url: `/community-enrich-product-list/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ['community-enrich-product-list',]
        }),
    }),
});

export const { useGetAllCommunityEnrichQuery, useGetAllCommunityEnrichProductsQuery, usePostEnrichProductsMutation, useGetCommunityEnrichProductDetailQuery, useCommunityEnrichEnquireMutation, useCommunityEnrichEnquireListQuery,useMyEnquireListQuery,useGetMyProductsAndServiceQuery,useEditProductOrServiceMutation ,useDeleteProductServiceMutation} = communityEnrich;
