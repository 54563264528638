  import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { customerApiModel } from "../../../models/customer/customer";
import { config } from "../../../base/constant";

export const customerApi = createApi({
  reducerPath: "customerApi", 
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set("Authorization", `Token ${localStorage.getItem('token')}`)
      return headers
    }
  }),
  tagTypes: ['api'],  
  endpoints: (builder) => ({
    getAllCustomer: builder.query<customerApiModel[], void>({
      query: () => ({
        url: "/api",
        method: "GET",
      }),
      providesTags: ['api']
    }),
    // getCustomer: builder.query<customer[], string>({
    //   query: (id) => `/customer/${id}`,
    //   providesTags: ['customer']
    // }),
    // addCustomer: builder.mutation<void, customer>({
    //   query: (customer) => ({
    //     url: "/customer",
    //     method: "POST",
    //     body: customer,
    //   }),
    //   invalidatesTags: ['customer']
    // }),
    // updateCustomer: builder.mutation<void, customer>({
    //   query: ({ id, ...rest }) => ({
    //     url: `/customer/${id}`,
    //     method: "PUT",
    //     body: rest,
    //   }),
    //   invalidatesTags: ['customer']
    // }),
    // deleteCustomer: builder.mutation<void, string>({
    //   query: (id) => ({
    //     url: `/customer/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ['customer']
    // }),
  }),
});

//export const { useGetAllCustomerQuery, useGetCustomerQuery, useAddCustomerMutation, useUpdateCustomerMutation, useDeleteCustomerMutation } = customerApi;
export const { useGetAllCustomerQuery } = customerApi;
