import {
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from "@reduxjs/toolkit";
import {customerApi} from "../../service/apiService/customer/customerApi";
import ErrorMessageReducer from "../reducer/errorMessageReducer";
import MessageContextSlice from "../reducer/message"
import HeaderHighlightSlice from "../reducer/headerHiglightReducer";
import {loginApi} from "../../service/apiService/login/loginApi";
import {communityEnrich} from "../../service/apiService/enrich/communityEnrich";
import {bannerApi} from "../../service/apiService/banner/banner";
import {projecListtApi} from "../../service/general/projects/projects";
import {couponApi} from "../../service/apiService/coupon/couponApi";
import {supportApi} from "../../service/apiService/support/supportApi";
import supportReducer from "../reducer/supportReducer";
import ReccuringSlice from "../reducer/recurrringReducer";
import {ReferralApi} from "../../service/apiService/referral/referral";
import {flatRegistrationApi} from "../../service/apiService/flatRegistration/flatRegistration";
import {customerEnrichApi} from "../../service/apiService/enrich/enrich";
import {visitorApi} from "../../service/apiService/visitors/visitorApi";
import {generalApi} from "../../service/general/generalApiService/generalApi";
import {enrichCartApi} from "../../service/apiService/enrichCart/cart";
import {eventListApi} from "../../service/apiService/events/events";
import {signupApi} from "../../service/apiService/signup/signup";
import {changePasswordApi} from "../../service/apiService/changePassword/changePasswordApi";
import {forgetPasswordApi} from "../../service/apiService/forgetPassword/forgetPassword";
import {ProductSearchFilterApi} from "../../service/apiService/enrich/search";
import {TenantApi} from "../../service/apiService/tenant/tenant";
import {myAccountApi} from "../../service/apiService/myAccount/myAccountApi";
import {sosContactApi} from "../../service/apiService/sosContact/sosContact";
import {testimonialApi} from "../../service/apiService/testimonial/testimonial";
import {propertyDetailApi} from "../../service/apiService/propertyDetails/propertyDetails";
import {calendarEventApi} from "../../service/apiService/eventCalendar/eventCalendarApi";
import {enrichServiceApi} from "../../service/apiService/enrich/enrichService";
import {amenityListApi} from "../../service/apiService/amenity/amenity";
import {historyApi} from "../../service/apiService/history/historyApi";
import {pollApi} from "../../service/apiService/poll/poll";
import {forumApi} from "../../service/apiService/forum/forumApi";
import {dailyHelpsApi} from "../../service/apiService/dailyHelps/dailyHelpsApi";
import {AppointmentRequestApi} from "../../service/apiService/appointmentRequest/appointmentRequestApi";
import {notificationListApi} from "../../service/apiService/notification/notification";
import {galleryApi} from "../../service/apiService/myGallery/myGallery";
import {fittedHomeApi} from "../../service/apiService/fittedHome/fittedHome";
import {assureApi} from "../../service/apiService/assure/assureApi";
import {maintenanceApi} from "../../service/apiService/maintenance/maintenance";
import {unitAssignApi} from "../../service/apiService/unitsAssign/unitAsign";
import {customerDetailsApi, customerDocDownloadApi} from "../../service/apiService/sales-force/customer-details";
import {salesAuthenticationApi} from "../../service/apiService/sales-force/get-auth-token";

const rootReducer = combineReducers({
    supportReducer: supportReducer,
    ErrorMessageReducer: ErrorMessageReducer,
    HeaderHighlightSlice: HeaderHighlightSlice,
    ReccuringSlice: ReccuringSlice,
    MessageContextSlice: MessageContextSlice,
    [customerApi.reducerPath]: customerApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [signupApi.reducerPath]: signupApi.reducer,
    [communityEnrich.reducerPath]: communityEnrich.reducer,
    [bannerApi.reducerPath]: bannerApi.reducer,
    [projecListtApi.reducerPath]: projecListtApi.reducer,
    [couponApi.reducerPath]: couponApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [ReferralApi.reducerPath]: ReferralApi.reducer,
    [flatRegistrationApi.reducerPath]: flatRegistrationApi.reducer,
    [customerEnrichApi.reducerPath]: customerEnrichApi.reducer,
    [visitorApi.reducerPath]: visitorApi.reducer,
    [generalApi.reducerPath]: generalApi.reducer,
    [eventListApi.reducerPath]: eventListApi.reducer,
    [TenantApi.reducerPath]: TenantApi.reducer,
    [enrichCartApi.reducerPath]: enrichCartApi.reducer,
    [changePasswordApi.reducerPath]: changePasswordApi.reducer,
    [forgetPasswordApi.reducerPath]: forgetPasswordApi.reducer,
    [myAccountApi.reducerPath]: myAccountApi.reducer,
    [ProductSearchFilterApi.reducerPath]: ProductSearchFilterApi.reducer,
    [sosContactApi.reducerPath]: sosContactApi.reducer,
    [testimonialApi.reducerPath]: testimonialApi.reducer,
    [propertyDetailApi.reducerPath]: propertyDetailApi.reducer,
    [calendarEventApi.reducerPath]: calendarEventApi.reducer,
    [enrichServiceApi.reducerPath]: enrichServiceApi.reducer,
    [amenityListApi.reducerPath]: amenityListApi.reducer,
    [historyApi.reducerPath]: historyApi.reducer,
    [pollApi.reducerPath]: pollApi.reducer,
    [forumApi.reducerPath]: forumApi.reducer,
    [dailyHelpsApi.reducerPath]: dailyHelpsApi.reducer,
    [AppointmentRequestApi.reducerPath]: AppointmentRequestApi.reducer,
    [notificationListApi.reducerPath]: notificationListApi.reducer,
    [galleryApi.reducerPath]: galleryApi.reducer,
    [fittedHomeApi.reducerPath]: fittedHomeApi.reducer,
    [assureApi.reducerPath]: assureApi.reducer,
    [maintenanceApi.reducerPath]: maintenanceApi.reducer,
    [unitAssignApi.reducerPath]: unitAssignApi.reducer,
    [customerDetailsApi.reducerPath]: customerDetailsApi.reducer,
    [customerDocDownloadApi.reducerPath]: customerDocDownloadApi.reducer,
    [salesAuthenticationApi.reducerPath]: salesAuthenticationApi.reducer,


});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getdefaultMiddleware) =>
        getdefaultMiddleware().concat([
            customerApi.middleware,
            loginApi.middleware,
            communityEnrich.middleware,
            bannerApi.middleware,
            projecListtApi.middleware,
            couponApi.middleware,
            supportApi.middleware,
            ReferralApi.middleware,
            flatRegistrationApi.middleware,
            customerEnrichApi.middleware,
            visitorApi.middleware,
            generalApi.middleware,
            eventListApi.middleware,
            enrichCartApi.middleware,
            signupApi.middleware,
            changePasswordApi.middleware,
            forgetPasswordApi.middleware,
            ProductSearchFilterApi.middleware,
            TenantApi.middleware,
            myAccountApi.middleware,
            sosContactApi.middleware,
            testimonialApi.middleware,
            propertyDetailApi.middleware,
            calendarEventApi.middleware,
            enrichServiceApi.middleware,
            amenityListApi.middleware,
            forumApi.middleware,
            historyApi.middleware,
            dailyHelpsApi.middleware,
            pollApi.middleware,
            AppointmentRequestApi.middleware,
            notificationListApi.middleware,
            galleryApi.middleware,
            fittedHomeApi.middleware,
            assureApi.middleware,
            maintenanceApi.middleware,
            unitAssignApi.middleware,
            customerDetailsApi.middleware,
            customerDocDownloadApi.middleware,
            salesAuthenticationApi.middleware,
        ]),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
