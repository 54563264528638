import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
    messageNotify: boolean;
    messageInfo: string;
    messageType:string;
    email:string;
    password:string;
};
const initialState: InitialState = {
    messageNotify: false,
    messageInfo: "",
    messageType:"",
    email:'',
    password:''

};
const MessageContextSlice = createSlice({
    name: "MessageContextSlice",
    initialState,
    reducers: {
        setMessageInfo: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.messageInfo = action.payload;
        },
        setMessageNotify: (
            state: InitialState,
            action: PayloadAction<boolean>
        ) => {
            state.messageNotify = action.payload;
        },
        setMessageType: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.messageType = action.payload;
        },
        setUserEmail: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.email = action.payload;
        },
        setUserPassword: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.password = action.payload;
        },
    },
});
export default MessageContextSlice.reducer;
export const {
    setMessageInfo,
    setMessageNotify,
    setMessageType,
    setUserEmail,
    setUserPassword,
} = MessageContextSlice.actions;
