import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";
export const flatRegistrationApi = createApi({
  reducerPath: "flatRegistrationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  tagTypes: ["registration"],

  endpoints: (builder) => ({
    flatRegistration: builder.mutation<any, any>({
      query: (flatRegistration: any) => ({
        url: "/customer-registration/",
        method: "POST",
        body: flatRegistration,
      }),
      invalidatesTags: ["registration"],
    }),
    getRegistrationSoltsDate: builder.query<any, void>({
      query: (id) => ({
        url: `/customer-registration/`,
        // url: `/customer-registration/?project_id=${id}`,
        method: "GET",
      }),
    }),
    getRegistrationdetails: builder.query<any, any>({
      query: () => ({
        url: `/customer-registration-date-confirmation/`,
        method: "GET",
      }),
      providesTags: ["registration"],
    }),
    getListOfCustomerProject: builder.query<any, void>({
      query: () => ({
        url: "/project-list/",
        method: "GET",
        params: {
          customer_project : "True"
      },
      }),
    }),
  }),
});
export const {
  useFlatRegistrationMutation,
  useGetRegistrationSoltsDateQuery,
  useGetRegistrationdetailsQuery,
  useGetListOfCustomerProjectQuery
} = flatRegistrationApi;
