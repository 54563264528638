import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const unitAssignApi = createApi({
    reducerPath: "unitAssignApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
            return headers
        }
    }),
    tagTypes: ['visitor'],
    endpoints: (builder) => ({
        getUnitsList: builder.query<any,any>({
            query: () => ({
                url: "/customer-active-unit/",
                method: "GET",
            }),
            providesTags: ['visitor']
        }),
        assignUnitNumber: builder.mutation<any, any>({
            query: (unitiNfo) => ({
                url: "/customer-active-unit/setcustomerunit/",
                method: "POST",
                body: unitiNfo,
            }),
            invalidatesTags: ['visitor']
        }),
        
    }),
});

export const {useGetUnitsListQuery,useAssignUnitNumberMutation } = unitAssignApi;
    