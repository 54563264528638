import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const ProductSearchFilterApi = createApi({
    reducerPath: "productSearch",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['product-search-filter'],
    endpoints: (builder) => ({
        productSearchFilterList: builder.query<any, { product: string, searchName: string,pages:number,applyCategories: string[] }>({
            query: ({ product, searchName,pages,applyCategories }) => ({
                url: "/product-search-filter/",
                method: "GET",
                params: {
                    product: product,
                    search: searchName,
                    page: String(pages),
                    categories: applyCategories.join(','), 
                },
            }),
            providesTags: ['product-search-filter']
        }),

    }),
});

export const { useProductSearchFilterListQuery } = ProductSearchFilterApi;



