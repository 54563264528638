import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type InitialState = {
  reccuringData: object
  isReccuringData: boolean,
  optionsLength: number,
  optionsData: any,
  enrichMethod: string
};
const initialState: InitialState = {
  reccuringData: {},
  isReccuringData: false,
  optionsLength: 0,
  optionsData: [],
  enrichMethod: ''
};

const ReccuringSlice = createSlice({
  name: "Support",
  initialState,
  reducers: {
    setReccuringData: (state: InitialState, action: PayloadAction<any>) => {
      state.reccuringData = action.payload;
    },
    setOptionsData: (state: InitialState, action: PayloadAction<any>) => {
      state.optionsData = action.payload;
    },
    isSetReccuringData: (state: InitialState, action: PayloadAction<any>) => {
      state.isReccuringData = action.payload;
    },
    setOptionLength: (state: InitialState, action: PayloadAction<any>) => {
      state.optionsLength = state?.optionsData.length
    },
    setEnrichMethod: (state: InitialState, action: PayloadAction<any>) => {
      state.enrichMethod = action.payload
    },
  },
});
export default ReccuringSlice.reducer;
export const { setReccuringData, isSetReccuringData, setOptionsData, setOptionLength,setEnrichMethod } = ReccuringSlice.actions;
