import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";


export const eventListApi = createApi({
    reducerPath: "event",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['events'],
    endpoints: (builder) => ({
        getAllListOfEvents: builder.query<any, void>({
            query: () => ({
                url: "/association-event/",
                method: "GET",
            }),
            providesTags: ['events']
        }),
        getSingleListOfEvents: builder.query<any, void>({
            query: (id) => ({
                url: `/association-event/${id}`,
                method: "GET",
            }),
            providesTags: ['events']
        }),
        
        AddJoinEvent: builder.mutation<any, object>({
            query: (joinEventInfo: object) => ({
              url: "/create-event-participants/",
              method: "POST",
              body: joinEventInfo,
            }),
            invalidatesTags: ["events"],
          }),
          updateJoinedEvent: builder.mutation<any, any>({
            query: (eventData:any) => ({
                url: `/create-event-participants/${eventData.id}/`,
                method: "PUT",
                body: eventData.eventData,
            }),
            invalidatesTags: ['events']
        }),
       
          getSingleEvent: builder.query<any, { id: any }>({
            query: ({ id }) => ({
              url: `/association-event/${id}/`,
              method: "GET",
            }),
            providesTags: ["events"],
          }),
    }),

});

export const { useGetAllListOfEventsQuery,useGetSingleEventQuery ,useAddJoinEventMutation,useGetSingleListOfEventsQuery,useUpdateJoinedEventMutation} = eventListApi;
