import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";


export const pollApi = createApi({
    reducerPath: "pollApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['poll'],
    endpoints: (builder) => ({  
        getSinglePoll: builder.query<any, void>({
            query: (id) => ({
                url: `/association-event/${id}`,
                method: "GET",
            }),
            providesTags: ['poll']
        }),    
    }),

});

export const {useGetSinglePollQuery} = pollApi;
