import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const signupApi = createApi({
    reducerPath: "signupApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${config().BASE_URL}`}),
    tagTypes: ['signup'],
    endpoints: (builder) => ({
        Signup: builder.mutation<any, any>({
            query: (signupInfo) => ({
                url: "/signup/",
                method: "POST",
                body: signupInfo,
            }),
        }),
    }),
});

export const { useSignupMutation } = signupApi;
