import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const salesAuthenticationApi = createApi({
    reducerPath: "salesAuthentication",
    baseQuery: fetchBaseQuery({
        baseUrl: config().SALESFORCE_AUTH_URL
    }),
    tagTypes: ['sf-auth-token'],
    endpoints: (builder) => ({
        postSfGetAuthToken: builder.mutation<any, void>({
            query: () => ({
                url: "/token",
                method: "POST",
                body: new URLSearchParams({
                    grant_type: "password",
                    client_id: "3MVG9wFikKwue8BmcC5Mq5KHnPHUcY7Xl06BUPNgWs0RgU_Q4DS1Q.E3G8FXYJAOw6JXyx81T1UfatJNyNxov",
                    client_secret: "29CEC3471B72150A152C128776B97164E79E8ADB56B21F42FFD0F426EAE2ECB1",
                    username: "pradeep.bijur@purplstack.com.sfps",
                    password: "TN2024casa!"
                }).toString(),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }),
            invalidatesTags: ['sf-auth-token']
        })

    }),

});

export const { usePostSfGetAuthTokenMutation } = salesAuthenticationApi;
