import {createSlice, PayloadAction, createAction} from "@reduxjs/toolkit";

export const errorReset = createAction("counter/reset");

type InitialState = {
    emailErrorMessage: string;
    passwordErrorMessage: string;
    confirmPasswordErrorMessage: string;
    oldPasswordErrorMessage: string;
    mobileNumberErrorMessage: string;
    relationshipErrorMessage: string;
    unitNumberErrorMessage: string;
    projectErrorMessage: string;
    nameErrorMessage: string;
    supportImageErrorMessage: string;
    serviceTypeErrorMessage: string;
    categoryTypeErrorMessage: string;
    productPriceErrorMessage: string;
    productOfferPriceErrorMessage: string;
    productImageErrorMessage: string;
    typeofQueryErrorMessage: string;
    typeofQuerySubErrorMessage: string;
    purposeOfVisitErrorMessage: string;
    dateErrorMessage: string;
    participantCountErrorMessage: string;
    venueErrorMessage: string;
    eventImageErrorMessage: string;
    otpErrorMessage: string;
    apiErrorMessage: string;
    preferedTimeErrorMessage: string;
    participantsErrorMessage: string;
    visitorTypeErrorMessage: string;
    tenantFirstNameErrorMessage: string;
    tenantLastNameErrorMessage: string;
    tenantAgreementImageErrorMessage: string;
    tenantAadharImageErrorMessage: string;
    tenantAadharLastImageErrorMessage: string;
    locationErrorMessage: string;
    relationStatusErrorMessage: string;
    date1ErrorMessage: string;
    date2ErrorMessage: string;
    date3ErrorMessage: string;
    customerPanErrorMessage: string;
    customerAdharErrorMessage: string;
    witnessPanErrorMessage: string;
    witnesAadharErrorMessage: string;
    startDateErrorMessage: string;
    endDateErrorMessage: string;
    amenityNoteErrorMessage: string;
    //forum
    forumTopicErrMessage: string;
    forumDescription: string
    reportCommentErrMessage: string
    //addPoll
    topicNameErrorMessage: string;
    topicDescriptionErrorMessage: string;
    questionTypeErrorMessage: string;
    endOnErrorMessage: string;
    startOnErrorMessage: string;
    minOptionsErrorMessage: string;
    maxOptionsErrorMessage: string;
    //request appointment
    requestDateErrorMessage: string;
    requestPurposeErrorMessage: string
//fittedHome
    fittedHomeDescription: string
//testimonial
    testimonialCommentErrorMessage: string;
    testimonialRatingErrorMessage: string;
    testimonialImageErrorMessage: string;
//calendar
    calendarDateErrorMessage: string;
    calendarQuantityErrorMessaege: string;
};
const initialState: InitialState = {
    emailErrorMessage: "",
    startDateErrorMessage: "",
    amenityNoteErrorMessage: "",
    endDateErrorMessage: "",
    passwordErrorMessage: "",
    confirmPasswordErrorMessage: "",
    oldPasswordErrorMessage: "",
    mobileNumberErrorMessage: "",
    relationshipErrorMessage: "",
    unitNumberErrorMessage: "",
    projectErrorMessage: "",
    nameErrorMessage: "",
    typeofQueryErrorMessage: "",
    typeofQuerySubErrorMessage: "",
    supportImageErrorMessage: "",
    serviceTypeErrorMessage: "",
    categoryTypeErrorMessage: "",
    productPriceErrorMessage: "",
    productOfferPriceErrorMessage: "",
    productImageErrorMessage: "",
    purposeOfVisitErrorMessage: "",
    dateErrorMessage: "",
    preferedTimeErrorMessage: "",
    participantCountErrorMessage: "",
    venueErrorMessage: "",
    eventImageErrorMessage: "",
    otpErrorMessage: "",
    apiErrorMessage: "",
    participantsErrorMessage: "",
    visitorTypeErrorMessage: "",
    tenantFirstNameErrorMessage: "",
    tenantLastNameErrorMessage: "",
    tenantAgreementImageErrorMessage: "",
    tenantAadharImageErrorMessage: "",
    tenantAadharLastImageErrorMessage: "",
    locationErrorMessage: "",
    relationStatusErrorMessage: "",
    date1ErrorMessage: "",
    date2ErrorMessage: "",
    date3ErrorMessage: "",
    customerPanErrorMessage: "",
    customerAdharErrorMessage: "",
    witnessPanErrorMessage: "",
    witnesAadharErrorMessage: "",
    forumTopicErrMessage: "",
    forumDescription: "",
    reportCommentErrMessage: "",
    //addPoll
    topicNameErrorMessage: "",
    topicDescriptionErrorMessage: "",
    questionTypeErrorMessage: "",
    endOnErrorMessage: "",
    startOnErrorMessage: "",
    minOptionsErrorMessage: "",
    maxOptionsErrorMessage: "",
    //request appointment
    requestDateErrorMessage: "",
    requestPurposeErrorMessage: "",
    //fittedHome
    fittedHomeDescription: "",
    //testimonial
    testimonialCommentErrorMessage: "",
    testimonialImageErrorMessage: "",
    testimonialRatingErrorMessage: "",
//calendar
    calendarDateErrorMessage: "",
    calendarQuantityErrorMessaege: "",
};

const ErrorSlice = createSlice({
    name: "ErroMessage",
    initialState,
    reducers: {
        setEmailErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.emailErrorMessage = action.payload;
        },
        setPasswordErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.passwordErrorMessage = action.payload;
        },
        setOldPasswordErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.oldPasswordErrorMessage = action.payload;
        },
        setConfirmPasswordErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.confirmPasswordErrorMessage = action.payload;
        },
        setMobNumErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.mobileNumberErrorMessage = action.payload;
        },
        setRelationshipErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.relationshipErrorMessage = action.payload;
        },
        setUnitNumErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.unitNumberErrorMessage = action.payload;
        },
        setProjectErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.projectErrorMessage = action.payload;
        },
        setNameErrMessage: (state: InitialState, action: PayloadAction<string>) => {
            state.nameErrorMessage = action.payload;
        },
        setTypeOfQueryErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.typeofQueryErrorMessage = action.payload;
        },
        setTypeOfQuerySubErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.typeofQuerySubErrorMessage = action.payload;
        },
        setSupportImageErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.supportImageErrorMessage = action.payload;
        },
        setServiceTypeErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.serviceTypeErrorMessage = action.payload;
        },
        setProductOfferPriceErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.productOfferPriceErrorMessage = action.payload;
        },
        setProductPriceErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.productPriceErrorMessage = action.payload;
        },
        setCategoryTypeErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.categoryTypeErrorMessage = action.payload;
        },
        setProductImageErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.productImageErrorMessage = action.payload;
        },
        setpurposeOfVisitErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.purposeOfVisitErrorMessage = action.payload;
        },
        setDateErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.dateErrorMessage = action.payload;
        },
        setPreferedTimeErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.preferedTimeErrorMessage = action.payload;
        },
        setParticipantCountErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.participantsErrorMessage = action.payload;
        },
        setVenueErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.venueErrorMessage = action.payload;
        },
        setEventImageErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.supportImageErrorMessage = action.payload;
        },
        setOtpErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.otpErrorMessage = action.payload;
        },
        setApiErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.apiErrorMessage = action.payload;
        },
        setVisitorTypeErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.visitorTypeErrorMessage = action.payload;
        },
        setTenantFirstNameErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.tenantFirstNameErrorMessage = action.payload;
        },

        setTenantLastNameErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.tenantLastNameErrorMessage = action.payload;
        },
        setTenantAgreementImageErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.tenantAgreementImageErrorMessage = action.payload;
        },
        setTenantAadharImageErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.tenantAadharImageErrorMessage = action.payload;
        },
        setTenantAadharLastImageErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.tenantAadharLastImageErrorMessage = action.payload;
        },
        setLocationErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.locationErrorMessage = action.payload;
        },
        setRelationErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.relationStatusErrorMessage = action.payload;
        },
        setDate1ErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.date1ErrorMessage = action.payload;
        },
        setDate2ErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.date2ErrorMessage = action.payload;
        },
        setDate3ErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.date3ErrorMessage = action.payload;
        },
        setCustomerPanErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.customerPanErrorMessage = action.payload;
        },
        setCustomerAadharErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.customerAdharErrorMessage = action.payload;
        },
        setWitnessPanErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.witnessPanErrorMessage = action.payload;
        },
        setWitnessAadharErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.witnesAadharErrorMessage = action.payload;
        },
        setAmenityStartDateErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.startDateErrorMessage = action.payload;
        },
        setAmenityEndDateErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.endDateErrorMessage = action.payload;
        },
        setAmenityNoteErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.amenityNoteErrorMessage = action.payload;
        },
        setForumTopicErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.forumTopicErrMessage = action.payload;
        },
        setForumDescriptionErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.forumDescription = action.payload;
        },

        setReportCommentErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.reportCommentErrMessage = action.payload;
        },
        //addPoll
        setTopicNameErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.topicNameErrorMessage = action.payload;
        },
        setTopicDescriptionErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.topicDescriptionErrorMessage = action.payload;
        },
        setQuestionTypeErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.questionTypeErrorMessage = action.payload;
        },
        setEndOnErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.endOnErrorMessage = action.payload;
        },
        setStartOnErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.startOnErrorMessage = action.payload;
        },
        setMinOptionsErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.minOptionsErrorMessage = action.payload;
        },
        setMaxOptionsErrorMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.maxOptionsErrorMessage = action.payload;
        },
        //requestAppointment
        setAppointmentDateErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.requestDateErrorMessage = action.payload;
        },
        setAppointmentPurposeErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.requestPurposeErrorMessage = action.payload;
        },
        setFittedHomeDescriptionErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.fittedHomeDescription = action.payload;
        },
        setTestimonialCommentErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.testimonialCommentErrorMessage = action.payload;
        },
        setTestimonialRatingErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.testimonialRatingErrorMessage = action.payload;
        },
        setTestimonialImageErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.testimonialImageErrorMessage = action.payload;
        },
        //calendar
        setCalendarDateErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.calendarDateErrorMessage = action.payload;
        },
        setCalendarQuantityErrMessage: (
            state: InitialState,
            action: PayloadAction<string>
        ) => {
            state.calendarQuantityErrorMessaege = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(errorReset, (state) => {
            return initialState;
        });

    },
});
export default ErrorSlice.reducer;
export const {
    setEmailErrorMessage,
    setAmenityNoteErrorMessage,
    setAmenityStartDateErrorMessage,
    setAmenityEndDateErrorMessage,
    setPasswordErrorMessage,
    setOldPasswordErrorMessage,
    setConfirmPasswordErrorMessage,
    setMobNumErrMessage,
    setRelationshipErrMessage,
    setProjectErrMessage,
    setUnitNumErrMessage,
    setNameErrMessage,
    setTypeOfQueryErrMessage,
    setTypeOfQuerySubErrMessage,
    setSupportImageErrorMessage,
    setServiceTypeErrorMessage,
    setCategoryTypeErrorMessage,
    setProductPriceErrorMessage,
    setProductOfferPriceErrorMessage,
    setProductImageErrorMessage,
    setpurposeOfVisitErrorMessage,
    setDateErrorMessage,
    setPreferedTimeErrorMessage,
    setParticipantCountErrorMessage,
    setVenueErrorMessage,
    setEventImageErrorMessage,
    setOtpErrorMessage,
    setApiErrorMessage,
    setVisitorTypeErrorMessage,
    setTenantFirstNameErrorMessage,
    setTenantLastNameErrorMessage,
    setTenantAgreementImageErrorMessage,
    setTenantAadharImageErrorMessage,
    setTenantAadharLastImageErrorMessage,
    setLocationErrorMessage,
    setRelationErrorMessage,
    setDate1ErrorMessage,
    setDate2ErrorMessage,
    setDate3ErrorMessage,
    setCustomerPanErrorMessage,
    setCustomerAadharErrorMessage,
    setWitnessPanErrorMessage,
    setWitnessAadharErrorMessage,
    setForumTopicErrMessage,
    setForumDescriptionErrMessage,
    setReportCommentErrMessage,
    setMaxOptionsErrorMessage,
    setTopicDescriptionErrorMessage,
    setTopicNameErrorMessage,
    setQuestionTypeErrorMessage,
    setEndOnErrorMessage,
    setMinOptionsErrorMessage,
    setStartOnErrorMessage,
    setAppointmentDateErrMessage,
    setAppointmentPurposeErrMessage,
    setFittedHomeDescriptionErrMessage,
    setTestimonialCommentErrMessage,
    setTestimonialRatingErrMessage,
    setTestimonialImageErrMessage,
    setCalendarDateErrMessage,
    setCalendarQuantityErrMessage
} = ErrorSlice.actions;
