import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const TenantApi = createApi({
    reducerPath: "tenantApi",
    baseQuery: fetchBaseQuery({
         baseUrl:config().BASE_URL,
         prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
            return headers
        }
        }),
    tagTypes: ['tenant'],
    endpoints: (builder) => ({
        addTenant: builder.mutation<any,any>({
            query: (tenantInfo) => ({
                url: "/tenant-list/",
                method: "POST",
                body:tenantInfo,
            }),
            invalidatesTags: ['tenant']
        }),
        getAllTenantList: builder.query<any, void>({
            query: (id) => ({
                url: `/tenant-list/`,
                method: "GET",
            }),
            providesTags: ['tenant']
        }),
        deleteTenant: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `/tenant-list/${id}/`,
                method: "DELETE",
            }),
            invalidatesTags: ['tenant']
        }),
        updateTenant: builder.mutation<any, any>({
            query: (tenantData: any) => ({
                url: `/tenant-list/${tenantData.id}/`,
                method: "PATCH",
                body: tenantData.formData,
            }),
            invalidatesTags: ['tenant']
        }),
        getSingleTenant: builder.query<any, { id: any }>({
            query: ({ id }) => ({
              url: `/tenant-list/${id}/`,
              method: "GET",
            }),
            providesTags: ["tenant"],
          }),
        
    }),
});

export const { useAddTenantMutation,useGetAllTenantListQuery,useDeleteTenantMutation,useGetSingleTenantQuery,useUpdateTenantMutation} = TenantApi;
