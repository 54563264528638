import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const sosContactApi = createApi({
  reducerPath: "sosContact",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  tagTypes: ["sosContact"],
  endpoints: (builder) => ({
    getListOfSOSContact: builder.query<any, void>({
      query: () => ({
        url: "/sos-contacts/",
        method: "GET",
      }),
      providesTags: ["sosContact"],
    }),

    addSosContact: builder.mutation<any, object>({
      query: (sosContactInfo: object) => ({
        url: "/sos-contacts/",
        method: "POST",
        body: sosContactInfo,
      }),
      invalidatesTags: ["sosContact"],
    }),
    deleteSosContact: builder.mutation<any, any>({
      query: (id) => ({
        url: `/sos-contacts/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["sosContact"],
    }),
    updateSosCustomer: builder.mutation<any, {id:any; updateSosData: any}>({
      query: ({ id, updateSosData} ) => ({
        url: `/sos-contacts/${id}/`,
        method: "PATCH",
        body: updateSosData,
      }),
      invalidatesTags: ["sosContact"],
    }),
  }),
});

export const {
  useGetListOfSOSContactQuery,
  useAddSosContactMutation,
  useDeleteSosContactMutation,
  useUpdateSosCustomerMutation,
} = sosContactApi;
