import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";


export const bannerApi = createApi({
    reducerPath: "Banner",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['banner'],
    endpoints: (builder) => ({
        getAllBanner: builder.query<any, void>({
            query: () => ({
                url: "/banner/",
                method: "GET",
            }),
            providesTags: ['banner']
        }),
        getSingleBanner: builder.query<any, string>({
            query: (id: string) => `/banner/${id}`,
            providesTags: ['banner']
        }),
        addBanner: builder.mutation<any, any>({
            query: (banner: object) => ({
                url: "/banner",
                method: "POST",
                body: banner,
            }),
            invalidatesTags: ['banner']
        }),
        updateBanner: builder.mutation<void, any>({
            query: ({ id, ...rest }) => ({
                url: `/banner/${id}`,
                method: "PUT",
                body: rest,
            }),
            invalidatesTags: ['banner']
        }),
        deleteBanner: builder.mutation<void, string>({
            query: (id: string) => ({
                url: `/banner/${id}`,
                method: "banner",
            }),
            invalidatesTags: ['banner']
        }),

    }),

});

export const { useGetAllBannerQuery , useGetSingleBannerQuery , useAddBannerMutation , useDeleteBannerMutation ,useUpdateBannerMutation  } = bannerApi;
