import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const generalApi = createApi({
    reducerPath: "generalApi",
    baseQuery: fetchBaseQuery({
         baseUrl:config().BASE_URL,
         prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
            return headers
        }
        }),
    tagTypes: ['category-list'],
    endpoints: (builder) => ({
        getAllListOfCategories: builder.query<any,void>({
            query: () => ({
                url: "/product-category-list/",
                method: "GET",
            providesTags: ['category-list']
            }),
        }),
        getAllRewardPointsHistory: builder.query<any,void>({
            query: () => ({
                url: "/reward-history/",
                method: "GET",
            providesTags: ['reward-history']
            }),
        }),
    }),
});

export const { useGetAllListOfCategoriesQuery , useGetAllRewardPointsHistoryQuery} = generalApi;
