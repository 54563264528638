import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const forgetPasswordApi = createApi({
    reducerPath: "forgetPasswordApi",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
    }),
    tagTypes: ["forget-password"],
    endpoints: (builder) => ({
        getotp: builder.mutation<any, Object>({
            query: (mail: object) => ({
                url: "/forgot-password-send-otp/",
                method: "POST",
                body: mail
            }),

        }),
        submitThePassword: builder.mutation<any, object>({
            query: (ForgetPasswordObj: object) => ({
                url: "/forgot-password/",
                method: "POST",
                body: ForgetPasswordObj,
            }),
        }),
    }),
});

export const { useGetotpMutation, useSubmitThePasswordMutation } = forgetPasswordApi;
