import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const propertyDetailApi = createApi({
  reducerPath: "propertyDetail",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  tagTypes: ["property-detail"],
  endpoints: (builder) => ({
    getMypropertyDetails: builder.query<any, void>({
      query: () => ({
        url: "/my-project/",
        method: "GET",
      }),
      providesTags: ["property-detail"],
    }),

    getProjectMilestone: builder.query<any, void>({
      query: () => ({
        url: "/project-milestone/",
        method: "GET",
      }),
    }),

    getPropertyServices: builder.query<any, void>({
      query: () => ({
        url: "/customer-property-services/",
        method: "GET",
      }),
    }),

    getSupportTeamDetails: builder.query<any, void>({
      query: () => ({
        url: "/customer-support-team/",
        method: "GET",
      }),
    }),

    getPropertyDocumments: builder.query<any, void>({
      query: () => ({
        url: "/customer-project-documents/",
        method: "GET",
      }),
    }),

    reqDoc: builder.mutation<any, any>({
      query: (data) => ({
        url: "/request-documents/",
        method: "POST",
        body: data,
      }),
    }),

  }),
});

export const {
  useGetMypropertyDetailsQuery,
  useGetProjectMilestoneQuery,
  useGetPropertyServicesQuery,
  useGetSupportTeamDetailsQuery,
  useGetPropertyDocummentsQuery,
 useReqDocMutation, 
} = propertyDetailApi;
