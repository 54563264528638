import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const enrichCartApi = createApi({
    reducerPath: "enrichCart",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['enrich-cart','enrichments'],
    endpoints: (builder) => ({
        addToCart: builder.mutation<any, void>({
            query: (productInfo: any) => ({
                url: `/enrich-cart-products/add_product/`,
                method: "POST",
                body: productInfo
            }),
            invalidatesTags: ['enrich-cart']
        }),
        getEnrichCartProducts: builder.query<any, void>({
            query: () => ({
                url: `/enrich-cart-products/`,
                method: "GET",
            }),
            providesTags: ['enrich-cart']
        }),
        updateCartProductQuantity: builder.mutation<any, void>({
            query: (productInfo: any) => ({
                url: `/enrich-cart-products/update_product_service_quantity/`,
                method: "PUT",
                body:productInfo
            }),
            invalidatesTags: ['enrich-cart']
        }),
        removeCartProduct: builder.mutation<any, void>({
            query: (obj: any) => ({
                url: `/enrich-cart-products/remove_cart/?cart_id=${obj.cart_id}`,
                method: "DELETE",
            }),
            invalidatesTags: ['enrich-cart',"enrichments"]
        }),
        apiPaymentProduct: builder.mutation<any, any>({
            query: (total:any) => ({
                url: `/payment-gateway/`,
                method: "POST",
                body:total
            }),
            invalidatesTags: ['enrich-cart']
        }),
        cartToOrder: builder.mutation<any, void>({
            query: () => ({
                url: `/cart-to-order/`,
                method: "POST",
            }),
            invalidatesTags: ['enrich-cart']
        }),
        cartOrderList: builder.query<any, {pages:any}>({
            query: ({pages}) => ({
                url: `/cart-to-order/`,
                method: "GET",
                params: {
                    page: pages,
                  },
            }),
            providesTags: ['enrich-cart']
          
        }),
        initializePayment: builder.mutation<any, any>({
            query: (buyNowData:any) => ({
                url: `/cart-to-order/`,
                method: "POST",
                body:buyNowData
            }),
          
        }),
        getEnrichProductDetail: builder.query<any, void>({
            query: (id) => ({

                url: `/enrich-product-list/${id}`,
                method: "GET",
            }),
            providesTags: ['enrich-cart']
        }),
        applyCoupon: builder.mutation<any, any>({
            query: (data) => ({
                url: `enrich-cart-products/apply_coupon/`,
                method: "PUT",
                body:data
            }),
            invalidatesTags:['enrich-cart']
        }),
        removeCoupon: builder.mutation<any, any>({
            query: (data) => ({
                url: `enrich-cart-products/remove_coupon/`,
                method: "PUT",
                body:data
            }),
            invalidatesTags:['enrich-cart']
        }),
    }),
});

export const { useAddToCartMutation, useGetEnrichCartProductsQuery, useUpdateCartProductQuantityMutation,useRemoveCartProductMutation,useApiPaymentProductMutation ,useCartToOrderMutation,useCartOrderListQuery ,useInitializePaymentMutation ,useGetEnrichProductDetailQuery ,useApplyCouponMutation,useRemoveCouponMutation} = enrichCartApi;
