import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const couponApi = createApi({
    reducerPath: "coupon",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,

        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('token')}`)
            return headers
        }   
    }),
    tagTypes: ['coupon-code-list'],
    endpoints: (builder) => ({
        getAllCoupons: builder.query<any, void>({
            query: () => ({
                url: "/coupon-code-list/",
                method: "GET",
            }),
            providesTags: ['coupon-code-list']
        }),

    }),
});

export const { useGetAllCouponsQuery } = couponApi;
