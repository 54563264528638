import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const calendarEventApi = createApi({
  reducerPath: "eventCalendarApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  tagTypes: ["calendar"],
  endpoints: (builder) => ({
    getListOfCalendarEvents: builder.query<any, {month:any,year:any}>({
      query: ({month,year}) => ({
        url: "/show-orders-events-by-date/",
        method: "GET",
        params: {
          month: month,
          year:year,
        },
      }),
      providesTags: ["calendar"],
    }),
    getListOfEventsByDate: builder.query<any, { date: any }>({
      query: ({ date }) => ({
        url: "/show-orders-events-by-date/",
        method: "GET",
        params: {
          date: date,
        },
      }),
      providesTags: ["calendar"],
    }),
    editTheReccuringProduct: builder.mutation<any, {reccuringId:any,editData:any}>({
      query: ({reccuringId,editData}) => ({
        url: `/recurring-ordered-dates/${reccuringId}/`,
        method: "PUT",
       body:editData
      }),
     invalidatesTags:['calendar']
    }),
    deleteTheReccuringProduct: builder.mutation<any, any>({
      query: (reccuringId) => ({
        url: `/recurring-ordered-dates/${reccuringId}/`,
        method: "DELETE",
      
      }),
     invalidatesTags:['calendar']
    }),
  }),
});

export const { useGetListOfCalendarEventsQuery, useGetListOfEventsByDateQuery ,useEditTheReccuringProductMutation ,useDeleteTheReccuringProductMutation} =
  calendarEventApi;
